import { Box, Center, Flex, Heading, Image, Text } from '@chakra-ui/react';
import OeLogo from 'components/OeLogo';
import { useIsWorkshopBlocked } from 'helpers/general';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const MaintenancePage = () => {
  const { t } = useTranslation();

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }

  return (
    <Box position="fixed" top={0} left={0} width="100vw" height="100vh" zIndex={9999} bg="white">
      <Flex h={'100vh'} textAlign="center" flexDir={'column'} justifyContent={'stretch'}>
        <Flex textAlign="center" justifyContent={'center'}>
          <OeLogo width={280} height={60} />
        </Flex>

        <Heading size={'lg'} mb={5}>
          {t('pages:maintenance.title')}
        </Heading>
        <Text px={10} fontSize="md">
          {t('pages:maintenance.message')}
        </Text>
        <Center>
          <Image src="/images/alex-computer.svg" alt="Alex the mechanic in front of a computer" w={'30%'} h={'auto'} />
        </Center>
      </Flex>
    </Box>
  );
};

export default MaintenancePage;
