import posthog from "posthog-js";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import config from './config';

export default function PostHogPageView() {

    let location = useLocation();

    useEffect(() => {
        if (posthog) {
            posthog.capture(
                '$pageview',
                {
                    '$current_url' : `${config.baseUrl}/signup`
                }
            )
        }
    }, [location]);

    return null;
}