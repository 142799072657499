import { isNil } from 'lodash';

export const manageErrorResponse = (props: {
  toastInstance: any | undefined;
  error: { status: number } | string;
  bypassToast?: boolean;
  customMessage?: string;
  hideRealResponse?: boolean;
  showValidation?: boolean;
  login?: (data: { returnTo: string }) => void;
  t: any;
}) => {
  const { toastInstance, error, bypassToast = false, customMessage = undefined, hideRealResponse = false, showValidation = false, t } = props;
  const { status, message, data = {} } = typeof error == 'string' ? JSON.parse(error) : error;

  //if user is trying to access record they are not allowed to see, they will see the 404 page
  if (hideRealResponse && [403, 404].includes(status)) {
    window.location.href = '/404';
  }
  let title = t(`errors:general`);
  let redirectToErrorBoundary = false;
  switch (status) {
    case 422:
      if (!showValidation) {
        //validation error detected, should be handled by the form
        return;
      }
      break;
    case 400:
      title = message ?? data?.message;
      break;
    case 500:
      title = t(`errors:500.message`);
      redirectToErrorBoundary = true;
      break;
    case 403:
    case 404:
    case 429:
    case 500:
      title = message ?? data?.message;
      break;
    default:
      break;
  }

  if (redirectToErrorBoundary) {
    return !isNil(customMessage) ? customMessage : isNil(message) ? data?.message : message;
  } else {
    if (toastInstance) {
      return toastInstance({
        title: !isNil(customMessage) ? customMessage : title,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  }
  return true;
};
