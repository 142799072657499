import { Box, Flex, Heading, Icon, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxDescription from 'components/labels/CheckBoxDescription';
import { validateVat } from 'helpers/general';
import { get, merge } from 'lodash';
import { postcodeValidator } from 'postcode-validator';
import * as React from 'react';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
// @ts-ignore
import useGeoLocation from 'react-ipgeolocation';
import { useAsyncDebounce } from 'react-table';
import * as yup from 'yup';

import { PartialStateCode } from '../../../api/types';
import { PortalButton } from '../../../components/buttons/PortalButton';
import { FONT_WEIGHTS } from '../../../global/Fonts';
import { formatTelephone } from '../../../helpers/localization';
import { simpleCheckBox, simpleInput } from '../../../helpers/makeFormFields';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';

const FORMFIELD_CONFIG = {
  spacing: 2,
};

export const SignupStepKeys = [
  'firstname',
  'lastname',
  'email',
  'telephone',
  'name',
  'vat_number',
  'country',
  'language',
  'city',
  'zipcode',
  'address',
  'consent_termsandconditions',
  'consent_dataprivacy',
  'telephone_prefix',
];
export interface ISPFSignupForm {
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  name: string;
  vat_number: string;
  country: string;
  language: string;
  city: string;
  zipcode: string;
  address: string;
  consent_termsandconditions: any;
  consent_dataprivacy: any;
  telephone_prefix?: string;
}

const SignupStep = (props: {
  isLoadingParent?: boolean;
  saveInState(x: any): void;
  data: Partial<WorkshopData>;
  setStep(y: number): void;
  backendErrors?: object;
  localizationProps: {
    countries: string[];
    languages: { [country: string]: string[] };
    zipcodeLength: PartialStateCode<string>;
    vatFormatting: PartialStateCode<string>;
    telephonePrefixes: PartialStateCode<string>;
  };
}) => {
  const location = useGeoLocation();
  const { t, i18n } = useTranslation();
  const {
    saveInState,
    data,
    backendErrors = {},
    setStep,
    localizationProps: { countries = [], languages = {}, vatFormatting = {}, telephonePrefixes = {} },
  } = props;

  const schema = yup
    .object({
      firstname: yup.string().label(t('forms:firstname.label')).required(),
      lastname: yup.string().label(t('forms:lastname.label')).required(),
      email: yup
        .string()
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .label(t('forms:email.label'))
        .required(),
      telephone: yup.string().label(t('forms:telephone.label')).required(),
      telephone_prefix: yup.string().label(t('forms:telephone.label')).required(),
      name: yup.string().label(t('forms:name.label')).required(),
      vat_number: yup.string().label(t('forms:vat_number.label')).required(),
      country: yup.string().label(t('forms:country.label')).oneOf(countries, t('errors:countries.valid')).required(),
      language: yup.string().label(t('forms:language.label')).required(),
      city: yup.string().label(t('forms:city.label')).required(),
      zipcode: yup.string().test('zipcode', t('forms:zipcode.invalid'), (val = '', context) => {
        if (val && context) {
          return postcodeValidator(val, context?.parent?.country);
        } else return false;
      }),
      address: yup.string().label(t('forms:address.label')).required(),
      consent_termsandconditions: yup.bool().label(t('forms:consent_termsandconditions.label')).required().oneOf([true], t('common:required')),
      consent_dataprivacy: yup.boolean().label(t('forms:consent_dataprivacy.label')).required().oneOf([true], t('common:required')),
    })
    .required();

  const { register, handleSubmit, reset, watch, setFocus, setValue, clearErrors, formState, setError, getValues } = useForm<ISPFSignupForm>({
    defaultValues: { ...data, country: location?.country },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const { isValid, submitCount } = formState;
  const errors = merge(formState.errors, backendErrors);
  const selectedLanguage = watch('language');
  const country = watch('country');

  useEffect(() => {
    reset({
      ...data,
      country: data?.country ?? location?.country,
    });
  }, [data, countries]);

  useEffect(() => {
    if (country) {
      setValue('telephone_prefix', telephonePrefixes[country]);
      setValue('language', languages?.[country]?.[0]);
    }
  }, [country]);

  useEffect(() => {
    if (selectedLanguage !== i18n.language) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  watch((data) => {
    if (data.language !== i18n.language) {
      i18n.changeLanguage(data.language);
    }
  });

  const onSubmit: SubmitHandler<ISPFSignupForm> = (formData) => {
    saveInState({ ...formData });
    setStep(1);
  };
  const onChangeVat = useAsyncDebounce((val) => {
    if (country !== 'CH' && country !== 'NO') {
      validateVat(val, country).then((data: boolean) => {
        // other solution is to build an custom flag, and fake the error messages on the vat input field
        if (!data) {
          setError('vat_number', {
            type: 'server',
            message: t('forms:vat_number.invalid'),
          });
          setTimeout(() => {
            setFocus('vat_number');
          }, 100);
        } else {
          clearErrors('vat_number');
        }
      });
    }
  }, 3000);

  const formWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box as="form" onSubmit={handleSubmit(onSubmit)} data-test-id="signup-form" width={formWidth}>
        <Stack spacing="5">
          <Heading size="xs">{t('workshop:registration.label')}</Heading>
          <Text color="muted" fontSize="sm" pb={'5'}>
            {t('workshop:registration.stepper.one.description')}
          </Text>
          <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
            {t('workshop:registration.stepper.one.info.company.label')}
          </Text>
          <Box>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'name',
                label: t('forms:name.label'),
                placeholder: t('forms:name.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'vat_number',
                label: t('forms:vat_number.label'),
                placeholder: t('forms:vat_number.placeholder'),
                register,
                description: t('forms:vat_number.description'),
                showAsTooltip: true,
                errors,
                disabled: !country,
                customClass: 'pii',
                schema,
                leftAddon: country === 'NO' ? '' : country !== 'CH' ? country : 'CHE',
                customHook: (val) => {
                  const formatter = get(vatFormatting, country);
                  if (formatter) {
                    setValue('vat_number', formatter(val));
                  }
                  onChangeVat(val);
                },
              })}
            </Stack>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'address',
                label: t('forms:address.label'),
                placeholder: t('forms:address.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'city',
                label: t('forms:city.label'),
                placeholder: t('forms:city.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'zipcode',
                label: t('forms:zipcode.label'),
                placeholder: t('forms:zipcode.placeholder'),
                register,
                errors,
                customHook: (val) => setValue('zipcode', val.replace(/ /g, '')),
                schema,
                customClass: 'pii',
                disabled: !country,
              })}
            </Stack>
          </Box>

          <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
            {t('workshop:registration.stepper.one.info.contact.label')}
          </Text>
          <Box>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'firstname',
                label: t('forms:firstname.label'),
                placeholder: t('forms:firstname.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'lastname',
                label: t('forms:lastname.label'),
                placeholder: t('forms:lastname.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
            </Stack>

            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'email',
                label: t('forms:email.label'),
                placeholder: t('forms:email.placeholder'),
                register,
                errors,
                disabled: true,
                customHook: (val) => setValue('email', val.replace(/ /g, '')),
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'telephone',
                label: t('forms:telephone.label'),
                placeholder: t('forms:telephone.placeholder'),
                customHook: (val) => setValue('telephone', formatTelephone(val, getValues('telephone_prefix'))),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
            </Stack>
          </Box>

          <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
            {t('forms:consent_termsandconditions.label')}
          </Text>

          <Box>
            {simpleCheckBox({
              disabled: !selectedLanguage,
              name: 'consent_termsandconditions',
              label: t('forms:consent_termsandconditions.label'),
              description: (
                <CheckBoxDescription
                  country={country}
                  selectedLanguage={selectedLanguage}
                  translationKey={'forms:consent_termsandconditions.info'}
                  preLinkText={'I`ve read and accept the'}
                  linkText={'terms and conditions'}
                  postLinkText={'that will take affect between my company and OE Service.'}
                />
              ),
              register,
              errors,
              schema,
              borderColor: 'gray.300',
              borderRadius: 'xs',
            })}

            {simpleCheckBox({
              disabled: !selectedLanguage,
              name: 'consent_dataprivacy',
              label: t('forms:consent_dataprivacy.label'),
              description: (
                <CheckBoxDescription
                  country={country}
                  selectedLanguage={selectedLanguage}
                  translationKey={'forms:consent_dataprivacy.info'}
                  preLinkText={'I`ve read and accept the'}
                  linkText={'data privacy statement'}
                />
              ),
              register,
              errors,
              schema,
              borderColor: 'gray.300',
              borderRadius: 'xs',
            })}
          </Box>
        </Stack>

        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          <PortalButton type="submit" data-test-id="signup-next-button" disabled={submitCount! > 0 && !isValid}>
            {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
          </PortalButton>
        </Flex>
      </Box>
    </Box>
  );
};

export default SignupStep;
